import { Region } from '@getpopsure/public-models';
import { getQuerySegmentFromPath } from 'util/pathSegmentUtil';

const regionLandingPageDictionary: Partial<Record<Region, string>> = {
  at: '/en-eu/austria/health-insurance/expat/short-term',
  be: '/en-eu/belgium/health-insurance/expat/short-term',
  it: '/en-eu/italy/health-insurance/expat/short-term',
  mt: '/en-eu/malta/health-insurance/expat/short-term',
  nl: '/en-eu/netherlands/health-insurance/expat/short-term',
  se: '/en-eu/sweden/health-insurance/expat/short-term',
};

export const getRegionLandingPageUrl = (
  newCountry: { languages: { id: string; label: string }[]; id: Region },
  asPath: string
): string => {
  // If the region has a fixed landing page, redirect to it
  if (regionLandingPageDictionary[newCountry.id]) {
    return `${window.location.origin}${
      regionLandingPageDictionary[newCountry.id]
    }${getQuerySegmentFromPath(asPath)}`;
  }

  // Else, build the new path based on the locale and the current path
  return `/${newCountry.languages[0].id}-${
    newCountry.id
  }/${getQuerySegmentFromPath(asPath)}`;
};
