import { Card, ChevronRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import { nonProductLinks } from 'constants/internalLinks';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import businessImage from 'public/static/components//NavigationBar/business.png';
import React from 'react';

import styles from './style.module.scss';

const Category = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: string;
  children: React.ReactNode;
}) => (
  <div className={styles.category}>
    <div className={`${styles.categoryHeader} d-flex ai-center`}>
      <Image src={icon} width={40} height={40} alt={title} />
      <div className={`tc-grey-600 fw-bold ${styles.categoryTitle}`}>
        {title}
      </div>
    </div>
    <div className={styles.divider} />
    {children}
  </div>
);

export const DropdownMenuWithCategory = ({
  menu,
}: {
  menu: NavigationProductCategory[];
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`br8 px16 ${styles.dropdown} ${styles.menuWithCategoriesContainer}`}
    >
      <div className="d-flex jc-between gap56 w100 pt32 pb48 mx-auto">
        {menu.map((category) => (
          <Category
            key={category.title}
            title={category.title}
            icon={category.icon}
          >
            {category.products.map(({ name, url }) => (
              <div className="mt24" key={name}>
                <LinkTo
                  href={url}
                  className={styles.productContainer}
                  aria-label={name}
                >
                  <div className={styles.productTitle}>{name}</div>
                </LinkTo>
              </div>
            ))}
          </Category>
        ))}
        <div className={styles.companyInsuranceCard}>
          <Card
            title={t(
              'component.navigation.product.business.title',
              'Company insurance'
            )}
            description={t(
              'component.navigation.product.business.description',
              'Extra coverage as a benefit for employees.'
            )}
            descriptionVariant="small"
            dropShadow={false}
            classNames={{
              wrapper: 'bg-primary-100 jc-between',
              children: 'mt8',
            }}
          >
            <Image
              src={businessImage}
              alt="Health and pension insurances on a phone screen"
              width={216}
              height={93}
            />
            <LinkTo
              href={nonProductLinks.BUSINESS.path}
              className={classNames(
                styles.companyLink,
                'p-btn--secondary-black w100 mt16 d-flex ai-center'
              )}
            >
              {t('component.navigation.product.business.cta', 'Explore')}
              <ChevronRightIcon size={16} noMargin />
            </LinkTo>
          </Card>
        </div>
      </div>
    </div>
  );
};
