import { Region } from '@getpopsure/public-models';
import { Radio } from '@popsure/dirty-swan';
import { flags } from 'constants/flags';
import { getLabelById, supportedLanguagesByCountry } from 'constants/languages';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ReactNode, useRef, useState } from 'react';
import {
  getCountryFromLocaleIdentifier,
  getLanguageFromLocaleIdentifier,
} from 'util/getCountryFromLocaleIdentifier';

import styles from './LanguageSwitcher.module.scss';
import { getRegionLandingPageUrl } from './utils/getRegionLandingPageUrl';

export const LanguageSwitcher = ({
  condensed = true,
  isEuLanguageSwitcherActive,
}: {
  condensed?: boolean;
  isEuLanguageSwitcherActive: boolean;
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { asPath, locale: currentLocale } = useRouter();
  const { t } = useTranslation();

  const buttonContainerRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(buttonContainerRef, () => setMenuIsOpen(false));

  const countries = flags(isEuLanguageSwitcherActive).map(
    ({ id, label, icon, iconAlt }) => ({
      id,
      label,
      icon,
      iconAlt,
      languages: supportedLanguagesByCountry[id].map((langId) => ({
        id: langId,
        label: getLabelById(langId),
      })),
    })
  );

  const selectedCountry =
    countries.find(
      (country) => country.id === getCountryFromLocaleIdentifier(currentLocale)
    ) || countries[0];

  const selectedLanguage =
    selectedCountry.languages.find(
      (lang) => lang.id === getLanguageFromLocaleIdentifier(currentLocale)
    ) || selectedCountry.languages[0];

  const handleLanguageSwitch = (url: string) => {
    window.location.href = url;
    setMenuIsOpen(!menuIsOpen);
  };

  interface CountryRadio {
    icon: () => ReactNode;
    title: string;
  }

  let countryOptions = {} as Record<Region, CountryRadio>;
  countries.forEach((country) => {
    countryOptions = {
      ...countryOptions,
      [country.id]: {
        icon: () => (
          <Image
            src={country.icon}
            alt={country.iconAlt}
            height={16}
            width={24}
          />
        ),
        title: country.label,
      },
    };
  });

  return (
    <div ref={buttonContainerRef}>
      <button
        className={`p-btn--secondary d-flex jc-between ai-center c-pointer br8 ${
          styles.dropdownButton
        } ${condensed ? styles.condensedButton : styles.normalButton}`}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        type="button"
      >
        <Image
          src={selectedCountry.icon}
          alt={selectedCountry.iconAlt}
          height={16}
          width={24}
          className="mr8"
        />
        {condensed ? selectedLanguage.id.toUpperCase() : selectedLanguage.label}
      </button>
      {menuIsOpen && (
        <div
          className={`p32 br8 ${styles.dropdownContainer} ${
            condensed ? '' : styles.dropdownContainerTopOverlay
          }`}
        >
          <p className="p-h3 mb16">
            {t('component.languageswitcher.language')}
          </p>
          <div className={`${styles.languageOption} mb24`}>
            {selectedCountry?.languages.map((lang) => {
              const locale = `${lang.id}-${selectedCountry.id}`;
              return (
                <div key={locale} className="mb8">
                  <input
                    name="language"
                    value={locale}
                    className="p-radio"
                    type="radio"
                    id={locale}
                    onChange={() => handleLanguageSwitch(`/${locale}${asPath}`)}
                    checked={selectedLanguage.id === lang.id}
                  />
                  <label htmlFor={locale} className="p-label p-label--bordered">
                    {lang.label}
                  </label>
                </div>
              );
            })}
          </div>
          <p className="p-h3">{t('component.languageswitcher.country')}</p>
          <p className={`p-p--small tc-grey-600 mb16 ${styles.description}`}>
            {t('component.languageswitcher.description')}
          </p>
          <div className={styles.countryContainer}>
            <Radio
              wide
              inlineLayout
              classNames={{
                label: styles.countryLabel,
                option: styles.countryOption,
              }}
              value={selectedCountry.id}
              onChange={(value) => {
                const newCountry =
                  countries.find((country) => country.id === value) ||
                  countries[0];
                handleLanguageSwitch(
                  getRegionLandingPageUrl(newCountry, asPath)
                );
              }}
              options={countryOptions}
              inlineIcon={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};
