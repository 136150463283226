import {
  Alpha2CountryCode,
  countryOrRegionNameFromAlphaCode,
  mapCountryOrRegionFlag,
  Region,
} from '@getpopsure/public-models';

interface Flag {
  id: Region;
  label: string;
  icon: string;
  iconAlt: string;
}

export const flags = (includeEuRegions: boolean): Flag[] => {
  const regionsForFlag = (
    includeEuRegions
      ? ['de', 'es', 'fr', 'at', 'be', 'it', 'mt', 'nl', 'se', 'eu']
      : ['de', 'es', 'fr']
  ) as Partial<Region>[];

  return regionsForFlag.map((r) => {
    const codeUpperCased = r.toUpperCase() as Alpha2CountryCode;
    return {
      id: r,
      label: countryOrRegionNameFromAlphaCode(codeUpperCased),
      icon: mapCountryOrRegionFlag[codeUpperCased],
      iconAlt: `flag of ${countryOrRegionNameFromAlphaCode(codeUpperCased)}`,
    };
  });
};
